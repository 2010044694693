import {Injectable} from "@angular/core";
import {Observable, Subject, throwError} from "rxjs";
import {Issue} from "./issue.interface";
import {SortablePageRequest} from "../api/api.interface";
import {SnackBarService} from "../snackbar/snackbar.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';



@Injectable()
export class IssueService {
  onUpdated: Subject<Issue> = new Subject<Issue>();
  openIssues = ['10', '20', '90'];
  issuesInProcess = ['11', '30', '40', '60', '70', '92'];

  constructor(private http: HttpClient, public snackBarService: SnackBarService) {
  }

  create(type: string): Observable<any> {
    return this.http.post(`/api/issues/${type}`, {})
      .pipe(
        catchError((err) => {
          console.log('No authority to create issue')
          if (err.status === 403 || err.status === 401) {
            this.snackBarService.info(`No authority to create issue (` + err.status + `).`);
          }else {
            this.snackBarService.info(`Error in creating new issue" (` + err.status + `).`);
          }
          console.error(err);
          return throwError(err);
        })
      )
  }


  get(referenceNo: string): Observable<any> {
    return this.http.get(`/api/issues/${referenceNo}`);
  }

  export(referenceNo: string, contentType: string = 'application/json'): Observable<any> {
    return this.http.get(`/api/issues/${referenceNo}/export`, {
      headers: {'Accept': contentType},
      responseType: 'text'
    })
      .pipe(
        catchError((err) => {
          console.log('No authority export issue')
          if (err.status === 403 || err.status === 401) {
            this.snackBarService.info(`No authority to export issue (` + err.status + `).`);
          }else {
            this.snackBarService.info(`Error in exporting issue" (` + err.status + `).`);
          }
          console.error(err);
          return throwError(err);
        })
      );
  }

  exportAll(contentType: string = 'application/json'): Observable<any> {
    return this.http.get('/api/issues/export', {
      headers: {'Accept': contentType},
      responseType: 'text'
    })
      .pipe(
        catchError((err) => {
          console.log('No authority export issue')
          if (err.status === 403 || err.status === 401) {
            this.snackBarService.info(`No authority to export issues (` + err.status + `).`);
          }else {
            this.snackBarService.info(`Error in exporting issues" (` + err.status + `).`);
          }
          console.error(err);
          return throwError(err);
        })
      );
  }

  copy(referenceNo: string): Observable<any> {
    return this.http.post(`/api/issues/${referenceNo}/clone`, {})
      .pipe(
        catchError((err) => {
          console.log('No authority to clone issue')
          if (err.status === 403 || err.status === 401) {
            this.snackBarService.info(`No authority to clone issue (` + err.status + `).`);
          }else {
            this.snackBarService.info(`Error in cloning issue" (` + err.status + `).`);
          }
          console.error(err);
          return throwError(err);
        })
      );
  }

  find(searchTerm: string): Observable<any> {
    const params = new HttpParams()
      .set('searchTerm', searchTerm)
      .set('sort', '-issue.referenceNo')
      .set('types', 'INDICATION_BOOKING,HELP_DESK,EXCEPTION_CLAIM')
      .set('size', '50')
      .set('page', '0');
    return this.http.get('/api/issues', {params});
  }

  findPages(request: SortablePageRequest<any>): Observable<any> {
    let params = new HttpParams()
      .set('page', request.page)
      .set('size', request.size)
      .set('sort', request.sort)
      .set('types', request['types'])
      .set('searchTerm', request['searchTerm']);

    if (request.order) {
      for (let order of request.order) {
        params = params.append('order', order);
      }
    }

    if (request['statuses']) {
      for (let status of request['statuses']) {
        params = params.append('status', status);
      }
    }

    if (request['companies']) {
      for (let company of request['companies']) {
        params = params.append('company', company);
      }
    }

    return this.http.get('/api/issues/pages', {params});
  }
  queryPages(query: any) {
    let params = new HttpParams()
      .set('page', query.page)
      .set('size', query.size)
      .set('sort', query.sort)
      .set('types', query.types)
      .set('searchTerm', query.searchTerm);

    return this.http.get('/api/issues/pages', {params});
  }

  findProductDescriptions(searchTerm: string): Observable<any> {
    let params = new HttpParams()
      .set('searchTerm', searchTerm);
    return this.http.get('/api/issues/product-descriptions', {params});
  }

  findSearchGrammar(): Observable<any> {
    return this.http.get('/api/issues/search-grammar');
  }

  patch(referenceNo: string, params: any): Observable<any> {
    return this.http.patch(`/api/issues/${referenceNo}`, params)
      .pipe(
        catchError((err) => {
          console.log('No authority to patch issue')
          if (err.status === 403 || err.status === 401) {
            this.snackBarService.info(`No authority to patch issue (` + err.status + `).`);
          } else {
            this.snackBarService.info(`Error in patching issue" (` + err.status + `). Try refreshing the page (Ctrl + F5).`);
          }
          console.error(err);
          return throwError(err);
        })
      );
  }

  // Strongly typed STORAGE_TYPE with either value UPLOADS or COSTS as denoting sub directories for storing/fetching files
  upload(referenceNo: string, storageType: string, file: File, id?: number): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('storageType', storageType);
    formData.append('file', file, file.name);
    if (id) {
      formData.append('id', String(id));
    }
    return this.http.post(`/api/issues/${referenceNo}/upload`, formData);
  }

  download(referenceNo: string, filename: string, fileType: string, filePath: string): Observable<any> {
    let params = new HttpParams()
      .set('filePath', filePath);
    return this.http.get(`/api/issues/${referenceNo}/download`, {params, responseType: 'blob'});
  }

  xlsx(filename: string): Observable<any> {
    let params = new HttpParams()
      .set('filename', filename);
    return this.http.get(`/api/issues/xlsx`, {params, responseType: 'blob'})
      .pipe(
        catchError((err) => {
          console.log('No authority to download workbook')
          if (err.status === 403 || err.status === 401) {
            this.snackBarService.info(`No authority to download workbook (` + err.status + `).`);
          } else {
            this.snackBarService.info(`Error in downloading workbook" (` + err.status + `).`);
          }
          console.error(err);
          return throwError(err);
        })
      );
  }

  reports(referenceNo: string, params): Observable<any> {
    return this.http.post(`/api/issues/${referenceNo}/reports`, params, {responseType: 'blob'})
      .pipe(
        catchError((err) => {
          console.log('No authority to download report')
          if (err.status === 403 || err.status === 401) {
            this.snackBarService.info(`No authority to download report (` + err.status + `).`);
          }else {
            this.snackBarService.info(`Error in downloading report" (` + err.status + `).`);
          }
          console.error(err);
          return throwError(err);
        })
      );
  }

  sendUpdate(issue: Issue) {
    this.onUpdated.next(issue);
  }
}
